import React from 'react';
import { useTranslation } from 'react-i18next';

import SEO from '../components/seo';
import { HeroState } from '../components/hero/hero-context';
import PageWrapper from '../components/page-wrapper/page-wrapper';
import FaqContent from '../content/de/faq-content';

const FAQPage = () => {
  const { t } = useTranslation('faq');
  return (
    <PageWrapper padding>
      <SEO title={t('header.title')} />
      <HeroState title={t('header.title')} imageName="machine--back-black" />
      <FaqContent />
    </PageWrapper>
  );
};

export default FAQPage;
