import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Trans } from 'react-i18next';
import Image from 'gatsby-image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import ContentColumn from '../../components/content-column/content-column';
import illustrationImage from '../../images/Funktionsschema_2.svg';
import illustrationImageFront from '../../images/Leva_Frontseite.svg';

const query = graphql`
  {
    image01: file(
      relativePath: { eq: "faq/Manument_Leva_Details_Bedienung_Hebel.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image02: file(relativePath: { eq: "faq/Wassertank_1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image03: file(relativePath: { eq: "faq/Manument_Leva_Details_Hebel.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image04: file(
      relativePath: { eq: "faq/Manument_Leva_Details_Seite_Dampf.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

const ManumentLevaContent = () => {
  const data = useStaticQuery(query);

  return (
    <Container>
      <Row className={'mb-lg-10 mb-5'}>
        <Col md={6} style={{ padding: 0 }}>
          <Image {...data.image01.childImageSharp} />
        </Col>
        <ContentColumn md={6}>
          <Trans i18nKey="text-01" ns="faq">
            <h2>Warum eine Handhebelmaschine?</h2>
            <p>
              Im Aspekt der unvergleichlichen Sinnlichkeit, die man erfährt,
              während man mit einer Handhebelmaschine einen Espresso von Hand
              zieht und extrahiert, sahen wir, das Team von MANUMENT, den
              Anlass, das klassische Leva-Verfahren wiederzubeleben. Allerdings
              haben klassische Maschinen stets eine Sache gemeinsam: sie sind
              traditionell mit den typischen technischen Problemen verbunden.
            </p>
            <p>
              Mit dem Ziel vor Augen, dass es Spaß machen, sicher und
              unkompliziert sein soll, an den perfekten Espresso zu gelangen,
              aktualisierten und optimierten wir also die Technik der
              ursprünglichen Handhebelmaschine bis ins letzte Detail, indem wir
              die neuesten technologischen und mechanischen Fortschritte und die
              bestmöglichen Materialien für die MANUMENT Leva-Maschine
              einsetzten.
            </p>
            <p>
              Das Ergebnis: Ein unvergleichlich sinnlicher, perfekter Espresso.
            </p>
          </Trans>
        </ContentColumn>
      </Row>
      <Row className={'mb-lg-10 mb-5'}>
        <Col
          md={6}
          className={'d-flex align-items-center justify-content-center'}
        >
          <img
            src={illustrationImage}
            alt="Manument Leva Maschine Illustration"
            width="500"
          />
        </Col>
        <ContentColumn md={6}>
          <Trans i18nKey="text-02" ns="faq">
            <h2>
              Wie wird das Wasser erhitzt?
              <br /> Wo genau? Und wie lang ist die Aufwärmzeit?
            </h2>
            <p>
              Für die MANUMENT Leva-Maschine haben wir selbstverständlich keinen
              herkömmlichen Boiler vorgesehen. Das MANUMENT-Ingenieur-Team
              setzte indes auf die Entwicklung einer technologischen Innovation:
              das Multi-Durchlauferhitzersystem.
            </p>
            <p>
              In zwei getrennten Heizungselementen, eins für Kaffeewasser und
              eins für Dampf und Heißwasser, wird ausschließlich die exakt
              nötige Menge Wasser für einen doppelten Espresso-Shot oder
              Heißwasser für das Milchaufschäumen erhitzt – und zwar immer erst
              in dem Moment, wenn heißes Wasser benötigt wird. Somit ist die
              Leva-Maschine jederzeit sofort einsatzbereit. Auch die Überhitzung
              und Temperaturschwankung bei längerer Benutzung, die bei
              klassischen Handhebelmaschinen auftreten, wird auf diese Weise
              vermieden.
            </p>
          </Trans>
          <Trans i18nKey="text-03" ns="faq">
            <h2>Woher weiß ich, mit welchem Druck ich arbeite?</h2>
            <p>
              Die MANUMENT Leva-Maschine besitzt zwei ca. 23 cm lange Federn.
              Diese üben einen fest definierten Druck aus, der bei Beginn der
              Extraktion bei 9 bar liegt. Während sich die Feder im Laufe der
              Extraktion entspannt, nimmt der Druck langsam ab.
            </p>
            <p>
              Statt Pressure Profiling bietet die MANUMENT Leva-Maschine mit dem
              Flow Profiling eine weitere Art, die Prozess der
              Espresso-Extraktion zu kontrollieren. Mit dem manuellen
              Wasser-Flow-System kann händisch kontrolliert die gewünschte
              Intensität des Wasserzuflusses bestimmt werden, die auf den
              Kaffeekuchen, also auf die gemahlene und getamperte Bohne im
              Siebträger, trifft.
            </p>
          </Trans>
        </ContentColumn>
      </Row>
      <Row className={'mb-lg-10 mb-5'}>
        <Col md={6} style={{ padding: 0 }}>
          <Image {...data.image02.childImageSharp} />
        </Col>
        <ContentColumn md={6}>
          <Trans i18nKey="text-04" ns="faq">
            <h2>
              Wie wird der Wassertank gefüllt? Kann die MANUMENT Leva-Maschine
              an das Festwassernetz angeschlossen werden?
            </h2>
            <p>
              Die MANUMENT Leva-Maschine besitzt einen Glaswassertank, der von
              der Maschine abgenommen und auf einer flachen, sauberen Fläche
              gefüllt werden kann. Natürlich ist es ebenfalls möglich, Wasser in
              den Tank zu füllen, ohne ihn von der Leva-Maschine abzunehmen –
              hierbei muss allerdings dringend darauf geachtet werden, dass kein
              Wasser verschüttet wird und ins Gehäuse der Maschine dringt.
            </p>
            <p>
              In Zukunft wird die MANUMENT Leva-Maschine auch mit
              Festwasseranschluss verfügbar sein.
            </p>
            <p>
              <strong>
                Wichtige Anweisung: Füllen Sie den Wassertank bitte stets mit
                ausreichend sauberem, kaltem und möglichst weichem Trinkwasser
                auf. Verwenden Sie ausschließlich gefiltertes Trinkwasser. So
                verlängern Sie das Entkalkungsintervall Ihrer MANUMENT
                Leva-Maschine in erheblichem Maße. Darüber hinaus profitieren
                Sie übrigens zudem von einer besseren Getränkequalität, da sich
                weicheres Wasser positiv auf die Geschmacksentfaltung von Kaffee
                und Tee auswirkt.
              </strong>
            </p>
          </Trans>
        </ContentColumn>
      </Row>
      <Row className={'mb-lg-10 mb-5'}>
        <Col md={6} style={{ padding: 0 }}>
          <Image {...data.image03.childImageSharp} />
        </Col>
        <ContentColumn md={6}>
          <Trans i18nKey="text-05" ns="faq">
            <h2>Warum ist die MANUMENT Leva-Maschine schräg gebaut?</h2>
            <p>
              Die MANUMENT Leva-Maschine besitzt einen Hebel, der länger
              ausfällt, als die Hebel traditioneller Handhebelmaschinen. Das hat
              einen ganz praktikablen Grund: Denn die Verlängerung des Hebels
              der MANUMENT Leva-Maschine reduziert die Kraft, die aufgewendet
              werden muss, um an ihm zu ziehen. Der zylindrisch geformte Körper
              der Maschine ist also deshalb angewinkelt, um den Hebel einfacher
              zu bedienen: angefangen von der verbesserten Möglichkeit, den
              Hebel zu greifen und zu bedienen, bis zur Verringerung des
              Verletzungsrisikos beim Zurückfahren des Hebels.
            </p>
          </Trans>
        </ContentColumn>
      </Row>
      <Row className={'mb-lg-10 mb-5'}>
        <Col md={6} style={{ padding: 0 }}>
          <Image {...data.image04.childImageSharp} />
        </Col>
        <ContentColumn md={6}>
          <Trans i18nKey="text-06" ns="faq">
            <h2>Welche Teile müssen am häufigsten gewartet werden?</h2>
            <p>
              Die MANUMENT Leva-Maschine sollte nach jeder Benutzung gereinigt
              werden: bitte folgen Sie der Anleitung im Benutzerhandbuch.
              Spätestens nach 1.000 Portionen Kaffee ist dann eine Entkalkung
              der Leva-Maschine nötig. Für das Entkalken eignet sich ein
              handelsübliches Entkalkungsmittel, das für Kaffeemaschinen
              vorgesehen ist. Im MANUMENT Benutzerhandbuch finden Sie eine
              Anleitung zum Entkalkungs-Vorgang.
            </p>
            <p>
              Das Wartungsintervall hängt im Wesentlichen von der
              Nutzungsintensität der Maschine und von der Qualität des
              verwendeten Wassers ab. Wenn Sie im Durchschnitt weniger als 15
              Portionen Kaffee pro Tag zubereiten, wird ein Serviceintervall von
              zwei Jahren empfohlen. Wenn Sie die Maschine öfter benutzen,
              veranlassen Sie bitte eine jährliche Wartung Ihrer Leva-Maschine.{' '}
            </p>
          </Trans>
        </ContentColumn>
      </Row>
      <Row>
        <ContentColumn md={6}>
          <Trans i18nKey="text-07" ns="faq">
            <h2>Wo kann ich die MANUMENT Leva-Maschine kaufen?</h2>
            <p>
              Direkt im Hause MANUMENT! Die MANUMENT Leva-Maschine ist exklusiv
              über die MANUMENT Webseite erhältlich.
            </p>
          </Trans>
          <Trans i18nKey="text-08" ns="faq">
            <h2>
              Wie wird die MANUMENT Leva-Maschine geliefert und installiert?
            </h2>
            <p>
              Wenn Sie eine MANUMENT Leva-Maschine bei uns bestellt haben, wird
              sich schnell ein Team-Mitglied der MANUMENT-Familie bei Ihnen
              melden, um die Lieferung und Installation mit Ihnen zu
              vereinbaren.
            </p>
          </Trans>
          <Trans i18nKey="text-09" ns="faq">
            <h2>
              Wann kann ich die MANUMENT Leva-Maschine auch in meinem Land
              erwerben?
            </h2>
            <p>
              Die erste Serie der MANUMENT Leva-Maschine wird innerhalb der
              DACH-Region zu kaufen sein. Ab 2021 werden wir den Verkauf der
              Leva-Maschine über Deutschland, Österreich und die Schweiz hinaus
              erweitern. In welchen Ländern es zu welchem Zeitpunkt soweit sein
              wird, darüber informieren wir Sie natürlich so früh wie möglich
              auf unserer Website. Oder lassen Sie sich diese und weitere
              relevante Neuigkeiten nicht entgehen, indem Sie den
              E-Mail-Newsletter abonnieren.
            </p>
          </Trans>
          <Trans i18nKey="text-10" ns="faq">
            <h2>Wird es eine Service-Stelle in meiner Nähe geben?</h2>
            <p>
              Das MANUMENT-Team arbeitet derzeit am Ausbau eines weltweiten
              Servicenetzes von Kaffeemaschinentechnikern. Wenn Sie Fragen
              haben, können Sie sich aber jetzt schon unter{' '}
              <a href={'mailto:service@manument.com'}>service@manument.com</a>{' '}
              mit uns in Verbindung setzen und Antwort und Hilfe aus allererster
              Hand erfahren: direkt von uns.
            </p>
          </Trans>
        </ContentColumn>
        <Col
          md={6}
          className={'d-flex align-items-center justify-content-center'}
        >
          <img
            src={illustrationImageFront}
            alt="Manument Leva Maschine Illustration"
            width="300"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ManumentLevaContent;
